import common from "./common";
import Auth from '../plugins/auth-plugin'

let logger = new common.Logger()
let axios = common.axios

export default class AdminService {

    removeUser(account_email) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/removeuser/" + account_email, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    addUser(account_email) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/adduser/" + account_email, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }


    deployFinishedModel(modelName, account) {
        
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/" + account + "/models/deploy/" + modelName, { headers: { "Authorization": bearer } }).then(res => {

                return res.data
            });
        });

    }
    async PushcombinedPortfolio(domainName, customerName) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/update/" + domainName + "/combined/" + customerName, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    async AddnewDomain(domainName) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/newportfolio/" + domainName + "/combined/", { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }
    //Get the lsit of customer with names
    async getCombinedcustomers() {
        const tokenResponse = await Auth.getAccessToken();
        let bearer = "Bearer " + tokenResponse.accessToken;
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/Combinedcustomers/", { headers: { "Authorization": bearer } });
        // console.log("successfully pulled " + res.data);
        return res.data.data;
    }

    async check_nameExists(namee, accountt) {
        const tokenResponse = await Auth.getAccessToken();
        let bearer = "Bearer " + tokenResponse.accessToken;
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/" + namee + "/checknameexists/" + accountt, { headers: { "Authorization": bearer } });
        return res.data;
    }
    getModelPortfolio() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/portfolio", { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });

    }

    getAccess() {
        return Auth.getAccessToken().then(tokenResponse => {
            
            let bearer = "Bearer " + tokenResponse.accessToken;
            
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/getAccess", { headers: { "Authorization": bearer } }).then(res => {
                console.log("getAccess");
                console.log(res.data);                
                return res.data
            }).catch(ex => { return { "Error": ex } });
        });

    }


    getCombinedPortfolio() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/combinedportfolio", { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    async getcurrentUserModel() {
        const tokenResponse = await Auth.getAccessToken();
        let bearer = "Bearer " + tokenResponse.accessToken;
        const res = await axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/getcurrentUserModel/", { headers: { "Authorization": bearer } });
        if (res.data.code === 201) {
            
            return res.data;
        }
        else {
            // console.log("getcurrentUserModel has returned empty");
            return "";
        }

    }

    deployModel(modelName) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/models/deploy/" + modelName, { headers: { "Authorization": bearer } }).then(res => {

                return res.data
            });
        });

    }
    getUsersList() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/userlist", { headers: { "Authorization": bearer } }).then((res) => {
                return res.data
            });
        });
    }

    getInternalUsersList() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/internaluserlist", { headers: { "Authorization": bearer } }).then((res) => {
                return res.data
            });
        });
    }
    geteUsersList(domain) {
        
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/externaluserlist/" + domain, { headers: { "Authorization": bearer } }).then((res) => {
                
                return res.data
            });
        });
    }

    getCustomerDomainList() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/customerlist", { headers: { "Authorization": bearer } }).then((res) => {
                // console.log(res.data);
                return res.data
            });
        });

    }

    updateCombinedPortfolio(portfolio) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/update/combined', portfolio, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }
    async checkModel(modelName, account, modelFile) {
        let formData = new FormData();
        formData.append("modelName", modelName)
        formData.append("account", account)
        formData.append('modelFile', modelFile);
        const tokenResponse = await common.Auth.getAccessToken();
        let bearer = "Bearer " + tokenResponse.accessToken;
        logger.logMessage(tokenResponse);
        try {
            const res = await axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/checkModel', formData, { headers: { 'Content-Type': "multipart/form-data", "Authorization": bearer } });
            logger.logMessage(res);
            return res.data;
        } catch (err) {
            return err.message;
        }
    }

    uploadModel(modelName, account, modelFile) {
        let formData = new FormData();
        formData.append("modelName", modelName)
        formData.append("account", account)
        formData.append('modelFile', modelFile);


        return common.Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            logger.logMessage(tokenResponse)
            return axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/uploadModel', formData, { headers: { 'Content-Type': "multipart/form-data", "Authorization": bearer } }).then(res => {
                logger.logMessage(res)
                return res.data;
            }).catch(err => {
                console.log(err);
                return err;
            });
        });
    }

    submitModel(model) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            return axios.post(process.env.VUE_APP_BACKEND_URL + '/admin/submitModel', model, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }
    get_indi_account() {
        let name = Auth.getLoggedInUserId()
        name = name.split("@")[1]
        return { "domain": name.replace(/[.]/g, ''), "name": (name.charAt(0).toUpperCase() + name.slice(1).split(".")[0]) }

    }
    getModelInfo(modelName, accountName) {
        /// if accountName is null, set ito indi account
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            // console.log(tokenResponse.accessToken)
            console.log("account is : " + accountName);
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/" + accountName + "/models/" + modelName, { headers: { "Authorization": bearer } }).then(res => {
                // console.log(res)
                return res.data.result
            });
        });

    }
    CheckModelStatus(modelName, accountName, modelexperiment) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            // console.log(tokenResponse.accessToken)
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/admin/" + accountName + "/checkmodel/" + modelName + "/" + modelexperiment, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }
}

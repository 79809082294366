import axios from 'axios'
import Auth from '../plugins/auth-plugin'

/*
   Inject auth header for all requests
*/
/*axios.interceptors.request.use(
    function (successfulReq) {

        Auth.getAccessToken().then(tokenResponse => {
            var headers = new Headers();
            var bearer = `Bearer ${tokenResponse.accessToken}`  //"Bearer " + tokenResponse.accessToken;
            headers.append("Authorization", bearer);
            successfulReq.headers = headers;
        })

        return successfulReq;
    },
    function (error) {
        return Promise.reject(error);
    }
);
*/


export default class FormulatorService {

    getPortfolioDetail() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/getPortfolio/combinedportfolio", { headers: { "Authorization": bearer } }).then(res => {

                let activePortfolio = res.data.data.Models[0].Models.filter((model) => {
                    return model.Display == true || model.Display == "true"
                })
                return activePortfolio
            });
        });

    }

    getCombinedPortfolio() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/user/combinedportfolio", { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    getPortfolio() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/portfolio/combined", { headers: { "Authorization": bearer } }).then(res => {

                return res.data
            });
        });

    }

    getUserPortfolio() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/portfolio/userportfolioupdated", { headers: { "Authorization": bearer } }).then(res => {

                return res.data
            });
        });

    }


    getModelInfo(modelName) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            console.log(tokenResponse.accessToken)
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/portfolio/models/" + modelName, { headers: { "Authorization": bearer } }).then(res => {
                console.log(res)
                return res.data.result
            });
        });

    }

    getModelAccountInfo(accountName, modelName) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            console.log(tokenResponse.accessToken)
            return axios.get(process.env.VUE_APP_BACKEND_URL + "/portfolio/" + accountName + "/models/" + modelName, { headers: { "Authorization": bearer } }).then(res => {
                console.log(res)
                return res.data.result
            });
        });

    }

    submitFormulationParameters(modelName, formulations,account) {
        
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            return axios.post(process.env.VUE_APP_BACKEND_URL + '/predictors/automl_model/' + modelName  + "||"  +   account + '/', formulations, { headers: { "Authorization": bearer } }).then(res => {
                return res.data.result
            });
        });
    }

    GetgrphicalFormulation(modelName, formulations) {

        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            return axios.post(process.env.VUE_APP_BACKEND_URL + '/predictors/graph_model/' + modelName + '/', formulations, { headers: { "Authorization": bearer } }).then(res => {
                return res.data.result
            });
        });
    }

    updateCostSettings(modelName, attributes, costUnit) {

        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;

            let requestPayload = { "CostSetting": attributes, "CostUnit": costUnit }

            return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + modelName + '/cost_settings/', requestPayload, { headers: { "Authorization": bearer } }).then(res => {
                console.log(res)
                return res.data
            });
        });
    }

    enroleUser(accountName,isAdmin) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.post(process.env.VUE_APP_BACKEND_URL + '/user-settings/' + 'modelName' + '/' + accountName + '/' + isAdmin, {}, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
            // return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + modelName + '/formulations/', formulations, { headers: { "Authorization": bearer } }).then(res => {
            //     return res.data
            // });
        });
    }

    updateModelFormulations(modelName, formulations) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + modelName + '/formulations/', formulations, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    // Replicatemodel(modelName, TargetAccount) {
    //     return Auth.getAccessToken().then(tokenResponse => {
    //         let bearer = "Bearer " + tokenResponse.accessToken;
    //         return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + modelName + '/formulations/' + TargetAccount, { headers: { "Authorization": bearer } }).then(res => {
    //             return res.data
    //         });
    //     });
    // }

    async SubmitQuery(textdata, username) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + username + '/submitquery/', textdata, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    updateModelFormulations_account(modelName, account_name, formulations) {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.post(process.env.VUE_APP_BACKEND_URL + '/portfolio/' + modelName + '/formulations/' + account_name, formulations, { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }

    getDefaultModel() {
        return Auth.getAccessToken().then(tokenResponse => {
            let bearer = "Bearer " + tokenResponse.accessToken;
            return axios.get(process.env.VUE_APP_BACKEND_URL + '/portfolio/defaultmodel', { headers: { "Authorization": bearer } }).then(res => {
                return res.data
            });
        });
    }


}
<template>
    <!-- <Panel header="" v-if="this.SharedState.state.modelName.includes('TS6300')"> -->
    <Panel header="" >
        <div class="modelInfo" >
            <div class="grid card" v-if="this.detaildescription">
                <div class="col-12">
                    <p>{{detaildescription}}</p>
                </div>
                <!--
                <div class="rowheader p-col-2">Resin</div>
                <div class="desc p-col-10">This is a commonly used Styrene acrylic resin with a solids level of 50%</div>

                <div class="rowheader p-col-2">TS6300:</div>
                <div class="desc p-col-10">Ti-Pure™ TS6300, a highly treated TiO2 pigment</div>
                <div class="rowheader p-col-2">UniversalTiO2:</div>
                <div class="desc p-col-10">In this experiment we used Ti-Pure R92+ but results can be extrapolated to other high quality universal pigments with limited error</div>
                <div class="rowheader p-col-2">CaCO3 0.8 and 5:</div>
                <div class="desc p-col-10">Commonly used high quality CaCO3 with D50 of 0.8 &mu;m 5 &mu;m respectively</div>
                <div class="rowheader p-col-2">Calcined Clay:</div>
                <div class="desc p-col-10">a calcined clay with a D50 of about 2 &mu;m and oil absorption of 55 g oil/100g</div>
                <div class="rowheader p-col-2">Flash Calcined Clay:</div>
                <div class="desc p-col-10">a flash calcined clay with an oil absorption of 74 g oil/100g</div>
                <div class="rowheader p-col-2">Talcum:</div>
                <div class="desc p-col-10">a commonly used talcum with a D50 of 4.5 &mu;m and oil absorption of 36 g oil/100g</div>
                <div class="rowheader p-col-2">Matt:</div>
                <div class="desc p-col-10">a matting agent with an oil absorption of 220 g oil/100g</div>
                <div class="rowheader p-col-2">Balance:</div>
                <div class="desc p-col-10">this is the remaining part of the formula containing water, thickeners, dispersants.</div>

                <div class="p-col-12 subheading">Properties</div>
                <div class="p-col-12"><small> The outcomes are expressed relative to the reference. This makes the outcome dimensionless and more robust against variations in ingredients and test methods.</small></div>

                <div class="rowheader p-col-2">Dry hiding power or spread rate:</div>
                <div class="desc p-col-10">The hiding power of the dry paint as determined via the weighed hiding method using the Ti-PureTM Spread Rate Calculator.The higher the value the higher the hiding power.</div>

                <div class="rowheader p-col-2">Dry hiding power or spread rate:</div>
                <div class="desc p-col-10">Wet hiding power: The hiding power of the wet paint as determined via the weighed hiding method using the Ti-PureTM Spread Rate Calculator. The higher the value the higher the hiding power.</div>

                <div class="rowheader p-col-2">Oiled hiding power:</div>
                <div class="desc p-col-10">
                    The hiding power of an oiled paint as determined via the weighed hiding method using the Ti-PureTM Spread Rate Calculator. The higher the value the higher the hiding power. By oiling the dried paint, all the pores are filled and
                    do not scatter light anymore. Therefore the oiled hiding power is a good measure for the amount of light that is truly scattered by the TiO2.
                </div>

                <div class="rowheader p-col-2">Pore hiding:</div>
                <div class="desc p-col-10">The amount of hiding power coming from the light scattered by the pores in the dry paint. In practice, it is calculated by subtracting the oiled hiding power from the dry hiding power.</div>

                <div class="rowheader p-col-2">Scrub sensitivity:</div>
                <div class="desc p-col-10">The washability performance as measured by the ISO-standard 11988. The reference point is a micron paint that is removed after 200 scrub cycles. The higher the number the lower the performance.</div>

                <div class="rowheader p-col-2">Gloss:</div>
                <div class="desc p-col-10">This is the gloss measured under an 85° angle.</div>

                <div class="rowheader p-col-2">Mudcracking:</div>
                <div class="desc p-col-10">
                    During the drying process of a paint, cracks can be formed. This is more pronounced as the thickness of the paint increases. The original result is expressed as the maximal thickness that is free from cracks. The higher the number
                    the better.
                </div>

                <div class="rowheader p-col-2">Burnishing:</div>
                <div class="desc p-col-10">The glossiness that appears when a matt paint is rubbed. The higher the number the glossier the paint becomes after rubbing, which is usually an undesired property.</div>

                <div class="rowheader p-col-2">Tinting strength:</div>
                <div class="desc p-col-10">A measure for the whitening power of the paint when a fixed amount of colorant is added.</div>

                <div class="rowheader p-col-2">SAR (Stain Absorption Rate):</div>
                <div class="desc p-col-10">A measure for how much dirt (measured by a black gel, mimicking finger prints) is absorbed by the paint. The higher number the more dirt is absorbed.</div>

                <div class="rowheader p-col-2">DPR (Dirt Pick Up Resistance):</div>
                <div class="desc p-col-10">A measure for how many dust particles are absorbed by the paint. The higher the number the more dust is absorbed.</div>

                <div class="rowheader p-col-2">PVC:</div>
                <div class="desc p-col-10">Pigment Volume Concentration.</div>

                <div class="rowheader p-col-2">CPVC:</div>
                <div class="desc p-col-10">Critical Pigment Volume Concentration.</div>

                <div class="rowheader p-col-2">Reduced Pigment Volume Concentration:</div>
                <div class="desc p-col-10">
                    PVC divided by CPVC CO2/Kgpaint or CO2/m2: A measure for the amount of CO2 required to make the paint on a Kg basis or to cover 1 m2 of wall at a contrast ratio of 0.98, respectively. Calculations are based on the type and amount
                    of ingredients, underlying data come from CEPE-database). The other environmental impacts as defined by CEPE are calculated equivalently.
                </div>

                <div class="p-col-12"></div>-->
            </div>
            <div class="grid card" v-else>
                <!-- <div class="col-12">Detailed information not available for the model - {{ this.SharedState.state.modelName }}.</div> -->
                <div class="col-12">&nbsp;{{modeldescription}}</div>
            </div>
        <div :style="{margin:'0px'}" v-if="modelname.toLowerCase().split(' ').includes('ts6300')"><a href="https://www.tipure.com/fr/-/media/files/tipure/legacy/ti-pure-ts-6300-tds.pdf" target="_blank">Click here for the Technical Datasheet of Ti-Pure™ TS6300 </a></div>
    </div> 
    </Panel>
    <!--<Panel header="" v-if="!this.SharedState.state.modelName.includes('TS6300')">
        <Panel header=""  >
        
    </Panel> -->
</template>
<script>
export default {
    name: 'ModelInfo',
    inject: ['SharedState'],
    props: {
        detaildescription: {
            type: String,
            default() {
                return null;
            },
        },
        modeldescription: {
            type: String,
            default() {
                return null;
            },
        },
        modelname: {
            type: String,
            default() {
                return null;
            },
        },
    },
};
</script>
<style lang="scss">
.modelInfo {
    .card {
        border-collapse: collapse;
        border-color: #d0cdcd;
        border-style: solid;
    }
    .card {
        padding: 0.4rem 0rem 0rem 0rem;
    }
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        padding: 0.3rem;

        vertical-align: middle;
    }

    .subheading {
        vertical-align: middle;
        background-color: dimgray;
        color: #efefef;
        border: #fff;
        border-width: 2px;
        font-weight: 600;
        padding: 0.8rem;
    }
}
</style>
import axios from 'axios'
import Auth from '../plugins/auth-plugin'


//axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

/*axios.interceptors.request.use(
    function (successfulReq) {

        Auth.getAccessToken().then(tokenResponse => {
            var headers = new Headers();
            var bearer = `Bearer ${tokenResponse.accessToken}`
            headers.append("Authorization", bearer);
            successfulReq.headers = headers;
        })

        return successfulReq;
    },
    function (error) {
        return Promise.reject(error);
    }
);*/
class Logger {
    logMessage(message) {
        console.log(message)
    }
    logError(message) {
        console.log("ERROR OCCURED");
        console.log(message);
    }
}

export default { axios, Auth, Logger }